/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import { FieldArray } from 'redux-form';
import render from './render/render.jsx';

type Props = {|
  form: string,
|};

const AddView = (props: Props): Element<'div'> => {
  const { form } = props;
  return (
    <div id="addResponseView">
      <Form noValidate>
        <FieldArray
          form={form}
          name="structuralResponses"
          component={render}
        />
      </Form>
    </div>
  );
};

export default AddView;
