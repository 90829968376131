/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import {
  CREATE_USER_PASSWORD_RESPONSE,
  FETCH_USER_RESPONSE,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_RESPONSE,
  SIGN_OUT_USER,
} from '#constants/actionTypes.js';

const initialState = {
  token: null,
  uuid: null,
  acceptedTerms: null,
};

const authenticated = (state = initialState, action = {}) => {
  switch (action.type) {
    case SIGN_IN_USER_REQUEST:
      return {
        ...state,
        token: null,
        uuid: null,
        acceptedTerms: null,
      };
    case CREATE_USER_PASSWORD_RESPONSE:
    case SIGN_IN_USER_RESPONSE:
      if (action.error) {
        return state;
      }
      return {
        ...state,
        token: action.payload.token,
        uuid: action.payload.user.uuid,
      };
    case FETCH_USER_RESPONSE:
      if (action.error) {
        return state;
      }
      return {
        ...state,
        acceptedTerms: action.payload.acceptedTerms,
      };
    case SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default authenticated;
