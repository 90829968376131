/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import isEqual from 'lodash/fp/isEqual.js';
import {
  CREATE_ORGANIZATION_RESPONSE,
  FETCH_ORGANIZATION_RESPONSE,
  FETCH_ORGANIZATIONS_RESPONSE,
  UPDATE_ORGANIZATION_RESPONSE,
} from '#constants/actionTypes.js';

const initialState = {
  byId: {},
};

const addOrUpdateOrg = (state, action) => {
  const { payload, payload: { uuid } } = action;
  const { byId } = state;
  return ({
    byId: { ...byId, [uuid]: payload },
  });
};

const organizations = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_RESPONSE: {
      if (action.error) {
        return state;
      }
      const { organizations: payloadOrgs } = action.payload.entities;
      const nextById = Object.keys(payloadOrgs).reduce((acc, uuid) => {
        const payloadOrg = payloadOrgs[uuid];
        const stateOrg = state.byId[uuid];
        return {
          ...acc,
          [uuid]: (
            isEqual(stateOrg, payloadOrg)
              ? stateOrg : payloadOrg
          ),
        };
      }, {});
      return {
        ...state,
        byId: nextById,
      };
    }
    case CREATE_ORGANIZATION_RESPONSE:
    case FETCH_ORGANIZATION_RESPONSE:
    case UPDATE_ORGANIZATION_RESPONSE: {
      if (action.error) return state;
      const { payload, payload: { uuid } } = action;
      const { byId } = state;
      return (
        isEqual(byId[uuid], payload)
          ? state
          : addOrUpdateOrg(state, action)
      );
    }
    default:
      return state;
  }
};

export default organizations;
