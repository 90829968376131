/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'reactstrap';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderUncontrolledInput from '#components/support/forms/RenderUncontrolledInput/index.jsx';
import { input, button, wrapper } from './ModeShapeUploader.module.scss';

type Props = {|
  accept: string,
  form: string,
  handleUpload: Function,
  isUploading: boolean | null,
  maxBytes: number,
  name: string,
  value: string | null,
  method: string,
|};

const ModeShapeUploader = (props: Props): Element<typeof Row> => {
  const {
    accept,
    form,
    handleUpload,
    isUploading,
    maxBytes,
    name,
    value,
    method,
  } = props;

  const [showSpinner, setShowSpinner] = useState(false);
  const [preUploadError, setPreUploadError] = useState(null);

  useEffect(() => {
    setShowSpinner(false);
  }, [method]);

  useEffect(() => {
    if (value && isUploading !== null) {
      setShowSpinner(isUploading);
    }
  }, [isUploading, value]);

  // TODO: figure out proper flow type for e
  const handleChange = (e: any) => {
    e.preventDefault();
    setPreUploadError(null);
    const newFile = e.target.files[0];
    if (newFile) {
      const { size } = newFile;
      if (size > maxBytes) {
        setPreUploadError(`File exceeds the maximum ${numeral(maxBytes).format('0b')}`);
      } else {
        setShowSpinner(true);
        handleUpload(newFile);
        e.target.value = ''; // clear target so you can upload the same file
      }
    }
  };
  return (
    <Row>
      <Col>
        <div id="modeShapeUploader" className="position-relative p-2">
          <div className={wrapper}>
            <Field
              name={name}
              id={name}
              type="file"
              component={RenderUncontrolledInput}
              accept={accept}
              onChange={handleChange}
              inputClassName={input}
            />
          </div>
          <SubmitButton
            submittingText="Uploading"
            submitting={showSpinner}
            type="button"
            className={button}
          >
            Upload
          </SubmitButton>
          {preUploadError && <div id={`${name}-feedback`} className="text-danger">{preUploadError}</div>}
          <FieldFeedback form={form} name={name} />
        </div>
      </Col>
      <Col>
        <div className="float-right">
          <a
            id="sampleUpload"
            href="https://s3.us-west-2.amazonaws.com/static-assets.hbrisk.com/products/design/samples/mode-shapes/sample-mode-shape-upload.csv"
            download
          >
            Sample Upload
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default ModeShapeUploader;
