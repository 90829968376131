/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import numeral from 'numeral';

// add optional 2 decimal formatting for quantity fields
const formatComponentPopulationItems = (componentPopulationItems) => (
  componentPopulationItems.map((component) => {
    const { performanceGroups } = component;
    return {
      ...component,
      performanceGroups: performanceGroups.map((group) => {
        const { locations } = group;
        return {
          ...group,
          locations: locations.map((location) => {
            const { quantityDir1, quantityDir2, quantityNonDir } = location;
            return {
              ...location,
              quantityDir1: numeral(quantityDir1).format('0.[00]'),
              quantityDir2: numeral(quantityDir2).format('0.[00]'),
              quantityNonDir: numeral(quantityNonDir).format('0.[00]'),
            };
          }),
        };
      }),
    };
  })
);

export default formatComponentPopulationItems;
