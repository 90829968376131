/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import {
  FETCH_COMPONENT_RESPONSE,
  FETCH_COMPONENTS_BY_RANGE_RESPONSE,
  FETCH_COMPONENTS_LAST_UPDATED_RESPONSE,
  UPDATE_COMPONENT_REQUEST,
  UPDATE_COMPONENT_RESPONSE,
  SET_CURRENT_COMPONENT,
  TOGGLE_COMPONENT_ARCHIVE_START,
  TOGGLE_COMPONENT_ARCHIVE_END,
} from '#constants/actionTypes.js';
import { COMPONENT_UUID } from '#actions/components/components.js';
import wasUpdatedAtLeastAsRecentlyAs from '#reducers/entities/support/wasUpdatedAtLeastAsRecentlyAs.js';

export const UUID = 'uuid';
export const BY_UUID = 'byUuid';
export const LAST_UPDATED = 'lastUpdated';
export const CURRENT = 'current';
export const UPDATING = 'updating';
export const IN_PROGRESS = 'inProgress';
export const ERROR = 'error';
export const TOGGLING_ARCHIVED = 'togglingArchived';

const initialState = {
  [BY_UUID]: {},
  [LAST_UPDATED]: null,
  [CURRENT]: {
    [UUID]: null,
    [UPDATING]: {
      [IN_PROGRESS]: false,
      [ERROR]: null,
    },
    [TOGGLING_ARCHIVED]: false,
  },
};

const components = (state = initialState, action = {}) => {
  const {
    error, type, payload, meta,
  } = action;
  switch (type) {
    case SET_CURRENT_COMPONENT: {
      return {
        ...state,
        [CURRENT]: {
          ...state[CURRENT],
          [UUID]: payload,
        },
      };
    }
    case UPDATE_COMPONENT_REQUEST: {
      const { [COMPONENT_UUID]: componentUuid } = meta;
      if (componentUuid !== state[CURRENT][UUID]) {
        return state;
      }
      return {
        ...state,
        [CURRENT]: {
          ...state[CURRENT],
          [UPDATING]: {
            [IN_PROGRESS]: true,
            [ERROR]: null,
          },
        },
      };
    }
    case UPDATE_COMPONENT_RESPONSE: {
      const { [COMPONENT_UUID]: componentUuid } = meta;
      const stateComponent = state[BY_UUID][componentUuid];
      const newComponent = wasUpdatedAtLeastAsRecentlyAs(stateComponent, payload)
        ? stateComponent
        : payload;

      if (componentUuid !== state[CURRENT][UUID]) {
        return state;
      }
      return {
        ...state,
        [BY_UUID]: {
          ...state[BY_UUID],
          [componentUuid]: newComponent,
        },
        [CURRENT]: {
          ...state[CURRENT],
          [UPDATING]: {
            [IN_PROGRESS]: false,
            [ERROR]: error ? payload : null,
          },
        },
      };
    }
    case TOGGLE_COMPONENT_ARCHIVE_START: {
      return {
        ...state,
        [CURRENT]: {
          ...state[CURRENT],
          [TOGGLING_ARCHIVED]: true,
        },
      };
    }
    case TOGGLE_COMPONENT_ARCHIVE_END: {
      return {
        ...state,
        [CURRENT]: {
          ...state[CURRENT],
          [TOGGLING_ARCHIVED]: false,
        },
      };
    }
    case FETCH_COMPONENTS_BY_RANGE_RESPONSE: {
      if (error) return state;
      const { validationTime } = meta;
      const { [BY_UUID]: byUuid, [LAST_UPDATED]: lastUpdated } = state;
      if (validationTime !== null && new Date(lastUpdated) < new Date(validationTime)) {
        return state;
      }
      const payloadComponents = payload.entities.components;

      // Note: this will not remove components if deleted from the database
      return {
        ...state,
        [BY_UUID]: {
          ...byUuid,
          ...Object.values(payloadComponents).reduce((acc, component) => {
            const { [UUID]: uuid } = component;
            return {
              ...acc,
              [uuid]: byUuid[uuid]
                ? {
                  ...byUuid[uuid],
                  ...component,
                } : component,
            };
          }, {}),
        },
      };
    }
    case FETCH_COMPONENT_RESPONSE: {
      return {
        ...state,
        [BY_UUID]: {
          ...state[BY_UUID],
          [payload[UUID]]: payload,
        },
      };
    }
    case FETCH_COMPONENTS_LAST_UPDATED_RESPONSE: {
      if (error) return state;
      return {
        ...state,
        [LAST_UPDATED]: payload[LAST_UPDATED],
      };
    }
    default:
      return state;
  }
};

export default components;
