/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  FormGroup, Row, Col,
} from 'reactstrap';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import { edpsByKey } from '#constants/edps/index.js';
import type { EdpKey } from '#constants/edps/index.js';
import StructuralResponseTable from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/EditView/Response/ResponseDetail/Table/index.js';
import StructuralResponsePlot from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/EditView/Response/ResponseDetail/Plot/index.js';
import StructuralResponseMethodSelectMenu from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/support/SourceSelectMenu/index.jsx';

import StructuralResponseUploader from './Uploader/index.js';

type EdpResponseProps = {
  modelId: string,
  form: string,
  field: string,
  responseIndex: number,
  edpKey: EdpKey,
  method: string,
  shouldShowResponse: boolean,
  shouldShowUploader: boolean,
}

const EdpStructuralResponse = ({
  form,
  modelId,
  field,
  edpKey,
  responseIndex,
  method,
  shouldShowResponse,
  shouldShowUploader,
}: EdpResponseProps): Element<'div'> => {
  const edp = edpsByKey[edpKey];
  const { excludedMethods } = edp;
  return (
    <div className={`${edpKey}-response-detail-view`} key={`response-${responseIndex}`}>
      <h3>
        {edp.name}
        {' '}
        <Tip tipId={edpKey} />
      </h3>
      <GridField
        name={`${field}.method`}
        form={form}
        options={{
          componentProps: {
            exclude: excludedMethods || [],
          },
          columnSize: 5,
          labelText: 'Source',
          ComponentType: StructuralResponseMethodSelectMenu,
        }}
      />
      { shouldShowUploader && (
        <StructuralResponseUploader
          form={form}
          name={`${field}.upload`}
          modelId={modelId}
          edpKey={edpKey}
          method={method}
          accept=".csv"
          maxBytes={1.6e7}
        />
      )}
      { shouldShowResponse && (
        <div>
          <FormGroup tag="fieldset">
            <legend>Direction 1</legend>
            <Row>
              <Col>
                <StructuralResponsePlot
                  modelId={modelId}
                  edpKey={edpKey}
                  direction={1}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <StructuralResponseTable
                  modelId={modelId}
                  edpKey={edpKey}
                  direction={1}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup tag="fieldset">
            <legend>Direction 2</legend>
            <Row>
              <Col>
                <StructuralResponsePlot
                  modelId={modelId}
                  edpKey={edpKey}
                  direction={2}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <StructuralResponseTable
                  modelId={modelId}
                  edpKey={edpKey}
                  direction={2}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
      )}
    </div>
  );
};

export default EdpStructuralResponse;
