/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import isEqual from 'lodash/fp/isEqual.js';
import omit from 'lodash/fp/omit.js';
import {
  CREATE_USER_RESPONSE,
  FETCH_USER_RESPONSE,
  FETCH_USERS_RESPONSE,
  UPDATE_USER_RESPONSE,
} from '#constants/actionTypes.js';

const initialState = {};

const addOrUpdateUser = (state, payloadUser) => {
  const { uuid } = payloadUser;
  return { ...state, [uuid]: payloadUser };
};

const byId = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USERS_RESPONSE: {
      if (action.error) {
        return state;
      }
      const { users: payloadUsers } = action.payload.entities;
      return Object.keys(payloadUsers).reduce((acc, uuid) => {
        const payloadUser = payloadUsers[uuid];
        const stateUser = state[uuid];
        return {
          ...acc,
          [uuid]: (
            isEqual(stateUser, payloadUser)
              ? stateUser : payloadUser
          ),
        };
      }, {});
    }
    case CREATE_USER_RESPONSE:
    case FETCH_USER_RESPONSE:
    case UPDATE_USER_RESPONSE: {
      if (action.error) return state;
      const { payload, payload: { uuid } } = action;
      const payloadUser = omit('acceptedTerms', payload);
      return isEqual(state[uuid], payloadUser) ? state : addOrUpdateUser(state, payloadUser);
    }
    default:
      return state;
  }
};

export default byId;
