/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Link, Navigate, useParams,
} from 'react-router-dom';
import {
  Button, Card, CardBody, Col, Row,
} from 'reactstrap';
import Response from './Response/index.jsx';

type Props = {|
  modelId: string,
  form: string,
|};

const EditView = (
  props: Props
): (
  Element<typeof Card> | Element<typeof Navigate>
) => {
  const {
    modelId,
    form,
  } = props;

  const { responseIndex } = useParams();
  const responseIndexInt = parseInt(responseIndex, 10);

  return (
    <Card id="editResponseView">
      <CardBody>
        <Row>
          <Col>
            <span className="float-right">
              <Button id="topCloseButton" tag={Link} color="link" to="..">Close</Button>
            </span>
          </Col>
        </Row>
        { responseIndex
          ? (
            <Row>
              <Col lg="12">
                <Response
                  form={form}
                  responseIndex={responseIndexInt}
                  modelId={modelId}
                />
              </Col>
            </Row>
          )
          : null}
        <Row>
          <Col>
            <Button id="bottomCloseButton" tag={Link} to="..">Back to Structural Responses Table</Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EditView;
