/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import capitalize from 'lodash/fp/capitalize.js';
import classNames from 'classnames';
import React from 'react';
import ReactTable from 'react-table';
import LinkCell from './LinkCell/index.jsx';
import ActionCell from './ActionCell/index.js';
import {
  responsesTable,
  responsesTableTd,
  responsesTableTh,
  td,
  th,
} from './StructuralResponsesTable.module.scss';

const columnsWithDefaults = (withoutDefaults: any) => withoutDefaults.map((column) => (
  {
    ...{
      className: classNames(td, responsesTableTd),
      headerClassName: classNames('font-weight-bold', th, responsesTableTh),
    },
    ...column,
  }
));

const myColumns = (form) => columnsWithDefaults([
  {
    id: 'name',
    accessor: 'name',
    Header: 'Structural Response',
    maxWidth: 375,
    Cell: LinkCell,
  },
  {
    id: 'methodDescription',
    accessor: 'methodDescription',
    Header: 'Source',
    maxWidth: 310,
    className: classNames(td, responsesTableTd),
  },
  {
    id: 'edpUnit',
    accessor: 'edpUnit',
    Header: 'Unit',
    maxWidth: 75,
    className: classNames(td, responsesTableTd),
  },
  {
    id: 'locationType',
    accessor: 'locationType',
    Header: 'Dimension',
    maxWidth: 100,
    Cell: (data) => {
      const { original: { locationType } } = data;
      return (
        <span>
          {capitalize(locationType)}
        </span>
      );
    },
  },
  {
    Header: '',
    id: 'action',
    sortable: false,
    maxWidth: 30,
    className: 'p-0',
    Cell: (data: Object) => {
      const edpKey = get('original.edpKey', data);
      const index = get('original.responseIndex', data);
      const isRequired = get('original.isRequired', data);
      return (
        <ActionCell
          edpKey={edpKey}
          index={index}
          form={form}
          isRequired={isRequired}
        />
      );
    },
  },
]);

type StructuralResponse = {
  index: number,
  edpKey: string,
  name: string,
  method: string,
  methodDescription: string,
};

type Props = {|
  data: Array<StructuralResponse>,
  error: string,
  form: string,
|}

const StructuralResponsesTable = (props: Props): Element<typeof ReactTable | 'div'> => {
  const { error, data, form } = props;

  if (error) {
    return (<div className="text-danger">{error}</div>);
  }

  return (
    <ReactTable
      className={classNames(['responses-table', responsesTable, '-striped', 'rounded'])}
      columns={myColumns(form)}
      data={data}
      defaultPageSize={100000}
      showPagination={false}
      minRows={0}
      resizable={false}
      getTrProps={(_, rowInfo) => {
        const id = `row-${get('original.edpKey', rowInfo)}`;
        return {
          id,
        };
      }}
    />
  );
};
export default StructuralResponsesTable;
